/**
 * ルーティング用 Mixin
 */
export default {
  methods: {
    linkToOtherWindow({name, params, query}) {
      let resolvedRoute = this.$router.resolve({
        name: name,
        params: params,
        query: query,
      });
      window.open(resolvedRoute.href, '_blank');
    }
  }
};
