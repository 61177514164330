/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { saveAs } from 'file-saver';
import encoding from 'encoding-japanese';
import * as iconv from "iconv-lite";

/**
 * 型付け加工した値を返却します
 * @param { * } value 値
 * @returns { * } 型付け加工した値
 */
const parse = value => {
  if (_.isNumber(value)) {
    return value;
  }
  if (_.isString(value)) {
    return `"${value}"`;
  }
  return '';
};

/**
 * 見出し行付きTSVを返却します
 * @param { Array } json 
 * @returns { String } 見出し行付きTSV文字列
 */
export const json2tsv = json => {
  var header = Object.keys(json[0]).join('\t') + '\n';

  var body = json.map(function(d){
      return Object.keys(d).map(function(key) {
          return parse(d[key]);
      }).join('\t');
  }).join('\n');

  return header + body;
}

/**
 * キー名を変更したオブジェクトを返却します
 * @param { String } prefix キー名の接頭辞
 * @param { Object } group 情報群オブジェクト
 * @returns { Object } キー名を変更したオブジェクト
 */
const changeKeyNames = (prefix, group) => {
  return _.reduce(group, (obj, value, key) => {
    obj[`${prefix}.${key}`] = value;
    return obj;
  }, {});
};

/**
 * TSVファイルをダウンロードします
 * @param { String } tsvText TSV文字列
 * @param { String } fileName ファイル名
 */
export const downLoadTsv = (tsvText, fileName) => {
  const blob = new Blob([tsvText], {type: 'text/tab-separated-values'});
	saveAs(blob, fileName);
};

/**
 * TSVを生成して返却します
 * @param { Object } context 呼び出し元コンポーネントのthis
 * @returns { String } TSV文字列
 */
export const createTsv = context => {

  const tables = _.reduce(context.schemas.tables, (txt, table) => {
    if (table.variableName.includes('GoodsLine')) {
      return txt;
    }
    _.forEach(table.groups, (group) => {
      _.forEach(group.children, (child) => {
        txt = txt + `${table.variableName}.${group.variableName}.${child.key}\t${parse(context.res[table.variableName][group.variableName][child.key])}\n`;
      });
    });
    return txt;
  }, '@tables\n') + '@end\n\n';

  const goodsLine = _.map(context.res.linkageGoodsLine, goods => {
    const price = _.find(context.cloneItems.linkageGoodsLinePrice, p => {
      return p.keyGrp.goodsLineSeq === goods.keyGrp.goodsLineSeq;
    });
    const record = {
      ...changeKeyNames('linkageGoodsLine.goodsGrp', goods.goodsGrp),
      ...changeKeyNames('linkageGoodsLine.quantityGrp', goods.quantityGrp),
      ...changeKeyNames('linkageGoodsLine.remarksGrp', goods.remarksGrp),
      ...changeKeyNames('linkageGoodsLinePrice.priceGrp', price.priceGrp),
      ...changeKeyNames('linkageGoodsLinePrice.amountGrp', price.amountGrp),
      ...changeKeyNames('linkageGoodsLinePrice.commercialGrp', price.commercialGrp),
      ...changeKeyNames('linkageGoodsLinePrice.remarksGrp', price.remarksGrp)
    };
    return record;
  });

  const goods = _.isEmpty(goodsLine) ? '' : '@goodsLine\n' + json2tsv(goodsLine) + '@end\n\n';

  const tsv = goods + tables;
  console.log(tsv);
  downLoadTsv(tsv, 'tsv.tsv');
}

const tsvParse = buffer => {

};

export const getCharSet = reader => {
  // 上記から、readerにファイルの中身がはいっている
   const buffer = reader.result;
   const data = new Uint8Array(buffer);
   const encode = encoding.detect(data); //detect() は、引数にUint8Arrayをとる
   console.log(encode);
   return encode;
};

export const decodeBuffer = (reader, file) => {
  const charSet = getCharSet(reader);
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = function() {
      resolve(reader.result);
    };
    reader.readAsText(file, charSet);
  });
};

// export const readTsv = e =>  {
//   const files = _.get(e, 'target.files[0]') ? e.target.files : e.dataTransfer.files;
//   const _file = _.get(e, 'target.files[0]') || _.get(e, 'dataTransfer.files[0]');
//   console.log(e);
//   if (!_file) {
//     return;
//   }
//   if (typeof FileReader === 'function') {
//     var file = e.target.files[0];
//     var reader = new FileReader();
//     reader.onload = function() {
//       const decodedString = decodeBuffer(reader);
//       console.log(decodedString);
//     };
//     reader.readAsArrayBuffer(file);
//   } else {
//     alert('Sorry, FileReader API not supported');
//   }
// }