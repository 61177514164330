import _ from 'lodash';
import dayjs from 'dayjs';
import store from '@/store';
import { AUTHORIZATION_STATUS_VARIABLES, DELIVERY_TERMS_VARIABLES, GOODS_ATTR_ID, PAYMENT_TERMS_VARIABLES, TRADE_TYPE_VARIABLES, PROCESS_PROGRESS_STATUS_VARIABLES, TRADING_PROGRESS_STATUS_VARIABLES, PROCESS_CANCEL_FLG, MODE_OF_TRANSPORT_VARIABLES, TYPE_OF_SERVICE_VARIABLES, TYPE_OF_SERVICE_FCL_LCL_VARIABLES, IP_DN_TYPE_VARIABLES, DELETE_FLG, ENTITY_PROGRESS_STATUS_VARIABLES, TRADE_TYPE_DISPLAY_VARIABLES, PERMIT_LINK_FLG_VARIABLES, AMENDMENT_FLG_VARIABLES, IP_REQUEST_STATUS_VARIABLES, IP_REQUEST_TYPE_VARIABLES } from 'lib-tw-common';
import { entityNames, processNames, processIds } from '@/dictionaries/map.js';
import { formatQuantity, formatCurrency } from '@/utils/searchUtil.js';
import { json2tsv, downLoadTsv } from '@/utils/tsv.js';
import { statusLabels } from '@/dictionaries/status.js';
import { i18n } from '@/main.js';

// 取引一覧のプロセス進捗ステータス表示用の配列を生成します
const flatProgressStatus = entityList => {
  const flatArray =  _.reduce(entityList, (res, o) => {
    res = res.concat(o.processStatus);
    return res;
  }, []);

  const flatList =  _.map(processIds, id => {
    return _.find(flatArray, {processId: id}) || {processId: id};
  });

  return _.reduce(flatList, (ret, p) => {
    const postfix = p.processId.match(/\d+/);
    const label = getProcessExShortName(p.processId) + (postfix ? '(' + parseInt(postfix) + ')' : '');
    const value = _.get(_.find(PROCESS_PROGRESS_STATUS_VARIABLES, {code: p.processProgressStatusCd}), 'label') || '';
    ret[label] = value;
    return ret;
  }, {});
};

// 取引一覧のエンティティ進捗ステータス表示用の配列を生成します
const flatEntityStatus = entityList => {
  return _.reduce(entityNames, (ret, o) => {
    const label = getEntityName(o.code);
    const progress = _.get(_.find(entityList, {entityTypeCd: o.code}), 'entityProgressStatusCd');
    const value = _.get(_.find(ENTITY_PROGRESS_STATUS_VARIABLES, {code: progress}), 'label') || '';
    ret[label] = value;
    return ret;
  }, {});
};

// eslint-disable-next-line no-unused-vars
const getProgressApproval = processId => {
  return _.get(processNames[processId.replace(/\d/g, '')], 'approval') || false;
};

const getParent = processId => {
  return _.get(processNames[processId.replace(/\d/g, '')], 'parent');
};

// eslint-disable-next-line no-unused-vars
const getEntityId = entityTypeCd => {
  return _.get(_.find(entityNames, {code: entityTypeCd}), 'entityId');
};

// eslint-disable-next-line no-unused-vars
const getEntityName = entityTypeCd => {
  return i18n.t(`Entity.${_.get(_.find(entityNames, {code: entityTypeCd}), 'nameEn')}`);
};

const getEntityNameByProcessId = processId => {
  if (!getParent(processId)) {
    return null;
  }
  return entityNames[getParent(processId)].nameEn;
};

// プロセスの省略名を取得します
const getProcessExShortName = processId => {
  return _.get(processNames[processId.replace(/\d/g, '')], 'nameExShort');
};

// セクション
// eslint-disable-next-line no-unused-vars
const getCustomerName = (row, key) => {
  const lowerKey = key.toLowerCase();
  const name = (_.get(row[key], `${lowerKey}CompanyName`) || _.get(row, `${lowerKey}CompanyName`) || '') + ' ' + (_.get(row[key], `${lowerKey}SectionName`) || _.get(row, `${lowerKey}SectionName`) || '');
  return name === ' ' ? '' : name;
};

const getCustomerLongName = (row, key) => {
  const lowerKey = key.toLowerCase();
  return (_.get(row[key], `${lowerKey}CompanyName`) || _.get(row, `${lowerKey}CompanyName`) || '') + ' ' + (_.get(row[key], `${lowerKey}SectionName`) || _.get(row, `${lowerKey}SectionName`) || '');
};

// ユーザー
const getUserName = (row, key) => {
  return _.get(row, `${key}UserName`) || _.get(row, `userName${_.capitalize(key)}`);
};

// LCプール一覧 確認銀行A or Dを返却します
const getRequestedConfirmationPartyOption = row => {
  return _.get(row, 'requestedConfirmationPartyOptionA') || _.get(row, 'requestedConfirmationPartyOptionD', '');
};

const formatter = (row, column, value, schemas) => {
  if (column.dataType === 'Date') {
    // 入力した日付で表示
    if (value && dayjs(value).isValid()) {
      return dayjs(value).format(_.get(_.find(schemas, { key: column.key }), 'formatString') || 'YYYY-MM-DD');
    } else {
      return '';
    }
  }
  if (column.dataType === 'YYYYMMDDDate') {
    // 入力した日付で表示
    if (!value) {
      return '';
    }
    value = String(value);
    if (dayjs(value).isValid()) {
      return dayjs(value).format(_.get(_.find(schemas, { key: column.key }), 'formatString') || 'YYYY-MM-DD');
    } else {
      return '';
    }
  }
  if (column.dataType === 'YYMMDDDate') {
    // 入力した日付で表示
    if (!value) {
      return '';
    }
    value = `20${value}`;
    if (dayjs(value).isValid()) {
      return dayjs(value).format(_.get(_.find(schemas, { key: column.key }), 'formatString') || 'YYYY-MM-DD');
    } else {
      return '';
    }
  }
  if (column.dataType === 'DateTime') {
    // 入力した日付時刻で表示
    if (value && dayjs(value).isValid()) {
      return dayjs(value).format(_.get(_.find(schemas, { key: column.key }), 'formatString') || 'YYYY-MM-DD HH:mm');
    } else {
      return '';
    }
  }
  if (column.dataType === 'DateTimeSeconds') {
    // 入力した日付時刻で表示（秒まで）
    if (value && dayjs(value).isValid()) {
      return dayjs(value).format(_.get(_.find(schemas, { key: column.key }), 'formatString') || 'YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  if (column.dataType === 'SystemDate') {
    // システム日付
    if (value && dayjs(value).isValid()) {
      return dayjs(value).utc().local().format(_.get(_.find(schemas, { key: column.key }), 'formatString') || 'YYYY-MM-DD');
    } else {
      return '';
    }
  }
  if (column.dataType === 'Today') {
    if (value && dayjs(value).isValid()) {
      return dayjs(value).format(dayjs(value).utc().local().isToday() ? 'HH:mm' : 'MMM.DD');
    } else {
      return '';
    }
  }
  if (column.dataType === 'TradingFlowId') {
    if (!row.tradingId && row.tradingFlowId) {
      return row.tradingFlowId;
    }
    return `${row.tradingId || ''}/${row.tradingFlowId || ''}`;
  }
  if (column.dataType === 'TradeTypeCd') {
    return _.get(_.find(TRADE_TYPE_VARIABLES, {code: row[column.key]}), 'label');
  }
  if (column.dataType === 'TradeTypeCdDisplay') {
    return _.get(_.find(TRADE_TYPE_DISPLAY_VARIABLES, { code: row[column.key] }), 'label');
  }
  if (column.dataType === 'PaymentTermsCd') {
    const code = row.paymentTermsCd || row[column.key];
    return _.get(_.find(PAYMENT_TERMS_VARIABLES, {code: code}), 'label');
  }
  if (column.dataType === 'DeliveryTermsCd') {
    const code = row.deliveryTermsCd || row[column.key];
    return _.get(_.find(DELIVERY_TERMS_VARIABLES, {code: code}), 'label');
  }
  if (column.dataType === 'ApprovalStatusCd') {
    return _.get(_.find(AUTHORIZATION_STATUS_VARIABLES, {code: row.approvalStatus}), 'label');
  }
  if (column.dataType === 'ModeOfTransport') {
    return _.get(_.find(MODE_OF_TRANSPORT_VARIABLES, {code: row[column.key]}), 'label');
  }
  if (column.dataType === 'TypeOfService') {
    return _.get(_.find(TYPE_OF_SERVICE_VARIABLES, {code: row[column.key]}), 'label');
  }
  if (column.dataType === 'TypeOfServiceFclLcl') {
    return _.get(_.find(TYPE_OF_SERVICE_FCL_LCL_VARIABLES, {code: row[column.key]}), 'label');
  }
  if (column.dataType === 'Currency') {
    const target = _.get(_.find(schemas, { key: column.key }), 'target');

    if (!target || !row[target] || !store.state.systemProperties.SYS_MST_CURRENCY) {
      return formatCurrency(value);
    }
    const rule = _.find(store.getters.getSysMstCurrency, {alphabeticCode: row[target]});
    if (!rule) {
      return formatCurrency(value);
    }
    // 小数点以下は通貨の指定桁で切り捨て0埋め
    return formatCurrency(value, rule.symbol, rule.minorUnit);
  }
  if (column.dataType === 'Quantity') {
    const target = _.get(_.find(schemas, { key: column.key }), 'target');
    return formatQuantity(value, row[target]);
  }
  // 商品梱包 容積計
  if (column.dataType === 'MeasurementTotal') {
    if (!_.every([row.length, row.width, row.height], _.isNumber)) {
      // L, W, Hが数値じゃない場合は空文字を返却
      return '';
    }
    const target = _.get(_.find(schemas, { key: column.key }), 'target');
    const result = row.length * row.width * row.height;
    return formatQuantity(result, row[target]);
  }
  if (column.dataType === 'CompanyRoleCd') {
    if (_.isArray(value) && value[0]) {
      return _.filter(_.values(value[0]).join(', '));
    } else {
      return '';
    }
  }
  // HSコード
  if (column.dataType === 'HsCode') {
    return _.map(_.filter(value, (o) => {
      return o.attrId === GOODS_ATTR_ID.HS_CODE && o.stringVal;
    }), o => o.stringVal).join('/');
  }
  // No エンティティが契約まではContract No 契約以降はInvoiceNoを表示
  if (column.dataType === 'DisplayNo') {
    return row.imCustomInvoiceNo || row.exCustomInvoiceNo || row.invoiceNo || row.contractNo || '';
  }
  // from、to
  if (column.dataType === 'Customer') {
    return getCustomerLongName(row, column.key);
  }
  // ユーザー
  if (column.dataType === 'User') {
    return getUserName(row, column.key);
  }
  // プロセス進捗ステータス
  if (column.dataType === 'StatusBar' || column.dataType === 'StatusIcon') {
    return _.get(_.find(PROCESS_PROGRESS_STATUS_VARIABLES, {code: value}), 'label');
  }
  // エンティティ進捗ステータス
  if (column.dataType === 'EntityStatusIcon' || column.dataType === 'StatusIcon') {
    return _.get(_.find(PROCESS_PROGRESS_STATUS_VARIABLES, {code: value}), 'label');
  }
  // フロー進捗ステータス
  if (column.dataType === 'TradingStatusIcon') {
    return _.get(_.find(TRADING_PROGRESS_STATUS_VARIABLES, {code: value}), 'label');
  }
  // エンティティ名
  if (column.dataType === 'EntityIcon') {
    return i18n.t(`Entity.${getEntityNameByProcessId(value)}`);
  }
  // 削除フラグ
  if (column.dataType === 'EventCancel') {
    return value === PROCESS_CANCEL_FLG.ON ? statusLabels.DELETE.label : '';
  }
  // 削除フラグ
  if (column.dataType === 'DeleteFlag' || column.dataType === 'DeleteFlagLine') {
    return value === String(DELETE_FLG.DELETED) || value === DELETE_FLG.DELETED ? statusLabels.DELETE.label : '';
  }
  // 許可書紐付けフラグ
  if (column.dataType === 'PermitLink') {
    return _.get(_.find(PERMIT_LINK_FLG_VARIABLES, {code: value}), 'label', '');
  }
  // 許可書訂正フラグ
  if (column.dataType === 'Amendment') {
    return _.get(_.find(AMENDMENT_FLG_VARIABLES, {code: value}), 'label', '');
  }
  // IP, DN ドキュメントタイプ
  if (column.dataType === 'DocType') {
    return _.get(_.find([{code: 'IP', label: 'I/P'},{code: 'DN', label: 'D/N'}], {code: value}), 'label');
  }
  // IP, DNタイプ
  if (column.dataType === 'IpDnType') {
    return _.get(_.find(IP_DN_TYPE_VARIABLES, {code: value}), 'label', '');
  }
  // IP Request Status
  if (column.dataType === 'IpreqStatus') {
    return _.get(_.find(IP_REQUEST_STATUS_VARIABLES, {code: value}), 'label', '');
  }
  // IP Request Type
  if (column.dataType === 'IpRequestType') {
    return _.get(_.find(IP_REQUEST_TYPE_VARIABLES, {code: value}), 'label', '');
  }
  // LCプール一覧 確認銀行A or D
  if (column.dataType === 'RequestedConfirmationPartyOption') {
    return getRequestedConfirmationPartyOption(row)
  }

  // 配列文字列（LCのmessageTypeなど）
  if (column.dataType === 'ArrayString') {
    if (_.isArray(value)) {
      return value.join(',');
    }
    return '';
  }

  // 許可書PDFファイル IPDNファイル数
  if (column.dataType === 'AttachFile') {
    return row.pdfFileName || row.fileNum;
  }

  if (column.dataType === 'Entity') {
    if (row.processId) {
      return i18n.t(`Entity.${getEntityNameByProcessId(row.processId)}`);
    } else {
      return value;
    }
  }

  if (column.dataType === 'Process' || column.dataType === 'ProcessLinkColor') {
    if (row.processId) {
      if (row.processId === 'TPARB' || row.processId === 'TPRB') {
        return i18n.t('Process.TPATPRB');
      }
      // if (row.processId.startsWith('FDCOR') || row.processId.startsWith('FDCOI')) {
      //   return i18n.t(`Process.${row.processId.replace(/\d/g, '')}`) + ' ' + _.last(row.processId);
      // }
      return i18n.t(`Process.${row.processId.replace(/\d/g, '')}`);
    } else if (row.processType) {
      return i18n.t(`Process.${row.processType.replace('PT_', '').toUpperCase()}`);
    } else {
      return value;
    }
  }

  return _.get(row, column.key);
};

const parse = (schemas, items) => {
  if (_.isEmpty(items)) {
    items.push({});
  }
  const data = _.map(items, row => {
    return _.reduce(schemas, (ret, column) => {
      let label = column.label;
      if (column.dataType === 'AlertIcon') {
        return ret;
      } else if (column.label === ' ' && column.dataType === 'TradingStatusIcon') {
        label = 'Flow Status';
      } else if (column.label === '' && (column.dataType === 'EventCancel' || column.dataType === 'DeleteFlag' || column.dataType === 'DeleteFlagLine')) {
        label = 'Delete Status';
      } else if (column.label === '' && column.dataType === 'EntityIcon') {
        label = 'Category';
      }

      label = i18n.te(`List.${label}`) ? i18n.t(`List.${label}`) : label;

      const value = row[column.key];

      // 取引一覧プロセス進捗ステータス
      if (column.dataType === 'EntityList') {
        return {...ret, ...flatEntityStatus(value), ...flatProgressStatus(value)};
      }

      const formatValue = formatter(row, column, value, schemas);
      ret[label] = (formatValue || formatValue === 0) || '';
      return ret;
    }, {});
  });
  return data;
};


// eslint-disable-next-line import/prefer-default-export
export const tsvDownLoad = (schemas, items, title) => {
  // console.log(schemas, items)
  const isEmpty = _.isEmpty(items);
  const data = parse(schemas, items);
  let tsvText = json2tsv(data);
  if (isEmpty) {
    tsvText = _.take(tsvText.split('\n'));
  }
  downLoadTsv(tsvText, `${title || 'list'}.tsv`);
};
