import _ from 'lodash';
import dayjs from 'dayjs';
import { mixinCancelRequests } from '@/store/ApiClient';
import { FROM_TO_TYPE, PROCESS_PROGRESS_STATUS, PROCESS_PROGRESS_STATUS_VARIABLES } from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';
import TwSelectSection from '@/components/atoms/TwSelectSection';
import TwBreadcrumbs from '@/components/molecules/TwBreadcrumbs';
import TwEntityButtons from '@/components/molecules/TwEntityButtons';
import TwHeader from '@/components/organisms/TwHeader';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import { sectionForPulldown, getStartDate, getEndDate, getYMDStartDate, getYMDEndDate, getYMDStartDateTime, getYMDEndDateTime, getProcessProgressStatus, getTradingFlowId, initialFlag, onDateRangeChange, onDateTimeChange } from '@/utils/searchUtil.js';
import mixinRouteUtils from '@/utils/mixinRouteUtils.js';
import { tsvDownLoad } from '@/utils/listToTsvUtils.js';
import { entityNames, processNames, } from '@/dictionaries/map.js';

/**
 * エンティティ一覧用 Mixin
 */
export default {
  mixins: [mixinRouteUtils, mixinCancelRequests],
  components: {
    TwButton,
    TwEntityIcon,
    TwSelectSection,
    TwBreadcrumbs,
    TwEntityButtons,
    TwHeader,
    TwTableSystem
  },
  data() {
    return {
      pageSize: 20,
      selection: false,
      expand: false,
      fromSections: [],
      toSections: [],
      defaultDate: dayjs().subtract(1, 'month').toDate(),
      openForm: false,
    };
  },
  computed: {
    formatSections() {
      return sectionForPulldown(this.sections);
    },
    multipleSelect() {
      return (model) => {
        return _.isArray(model) && model.length > 1;
      };
    },
    singleSelect() {
      return (model) => {
        return _.isArray(model) && model.length === 1;
      };
    },
    getEntity() {
      return _.find(entityNames, {code: this.entityCode}) || {};
    },
    PROCESS_PROGRESS_STATUS_VARIABLES() {
      const options = this.getEntity.approval ? [
        PROCESS_PROGRESS_STATUS.NOT_STARTED,
        PROCESS_PROGRESS_STATUS.F_INPUT_IN_PROGRESS,
        PROCESS_PROGRESS_STATUS.F_AWAITING_APPROVAL,
        PROCESS_PROGRESS_STATUS.T_AWAITING_CONFIRMATION,
        PROCESS_PROGRESS_STATUS.T_AWAITING_APPROVAL,
        PROCESS_PROGRESS_STATUS.DONE
      ] : [
        PROCESS_PROGRESS_STATUS.NOT_STARTED,
        PROCESS_PROGRESS_STATUS.F_INPUT_IN_PROGRESS,
        PROCESS_PROGRESS_STATUS.T_AWAITING_CONFIRMATION,
        PROCESS_PROGRESS_STATUS.DONE
      ];
      return _.filter(PROCESS_PROGRESS_STATUS_VARIABLES, o => _.includes(options, o.code));
    },
    companyId() {
      return this.$store.state.companyId;
    },
  },
  watch: {
    companyId() {
      this.getSection(FROM_TO_TYPE.FROM);
      this.getSection(FROM_TO_TYPE.TO);
    }
  },
  created() {
    if (this.companyId) {
      this.getSection(FROM_TO_TYPE.FROM);
      this.getSection(FROM_TO_TYPE.TO);
    }
  },
  methods: {
    getSection(fromto) {
      const query = {
        entityType: this.entityCode,
        processId: null,
        fromToFlg: fromto,
        deleteType: 0,
      };

      this.$store.dispatch('GET_SECTIONS', query)
      .then(res => {
        if (fromto === FROM_TO_TYPE.FROM) {
          this.fromSections = sectionForPulldown(res.customerSectionList);
        } else {
          this.toSections = sectionForPulldown(res.customerSectionList);
        }
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 検索欄の開閉を切り替えます
    openToggle() {
      this.openForm = !this.openForm;
    },
    rowClick(row) {
      setTimeout(() => {
        this.$router.push({
          name: `Process${_.capitalize(row.processId.replace(/\d/g, ''))}`,
          params: { entityId: row.entityId, processSeq: row.processSeq, _processId: row.processId.toLowerCase() },
        });
      });
    },
    rowDbClick(row) {
      this.linkToOtherWindow({
        name: `Process${_.capitalize(row.processId.replace(/\d/g, ''))}`,
        params: { entityId: row.entityId, processSeq: row.processSeq, _processId: row.processId.toLowerCase() }
      });
    },
    getStartDate,
    getEndDate,
    getYMDStartDate,
    getYMDEndDate,
    getYMDStartDateTime,
    getYMDEndDateTime,
    getProcessProgressStatus,
    getTradingFlowId,
    initialFlag,
    onDateRangeChange,
    onDateTimeChange,
    async onDropDownClick(command) {
      // 一覧をTSV化してダウンロードします
      if (command === 'tsv') {
        const items = await this.fetchAll();
        tsvDownLoad(this.schemas, items);
      // mixinTableSettingが読み込まれている場合に使用（mixinEntityListと対応ヵ所が被ってきた場合は統合すること）
      } else if (command === 'openTableSettingDialog') this.isOpenTableSettingDialog = true;
      else if (command === 'changeTableWidth') this.changeWidthMode();
      else if (command === 'resetTableSetting') this.resetTableSetting();
    },
    // プロセスプルダウンの中身を返却します
    getProcesses() {
      if (!this.categories) {
        return [];
      }
      return _.map(this.categories, o => {
        const children = _.map(_.filter(processNames, {parent: o.entityId}), p => {
          return Object.assign(p, {code: p.processType, name: this.$t(`Process.${p.processType.replace('PT_', '').toUpperCase()}`)});
        });
        return Object.assign({children: children}, o);
      });
    }
  }
};
